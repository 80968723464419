import axios from 'axios'
import { getQueryParam } from '@/services/util'

const API_URL = process.env.VUE_APP_API_URL
const API_KEY = getApiKey()

const api = {
    post: async (path, payload) => {
        const url = new URL(path, API_URL)
        const response = await axios.post(url, payload, {headers: getHeaders()})
        return response.data
    },
    get: async (path) => {
        const url = new URL(path, API_URL)
        const response = await axios.get(url, {headers: getHeaders()});
        return response.data;
    }
}

function getApiKey() {
    return getQueryParam('apiKey')
}

function getHeaders() {
    return {
        'x-api-key': API_KEY
    }
}

export default api