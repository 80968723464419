export function getFileExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

export function getQueryParam(name) {
    let uri = window.location.href.split('?')
    if (uri.length == 2) {
        let vars = uri[1].split('&')
        let getVars = {}
        let tmp = ''
        vars.forEach(function(v){
            tmp = v.split('=')
            if(tmp.length == 2) {
                getVars[tmp[0]] = tmp[1]
            }
        });
    return getVars[name]
    }
}

export function setQueryParam(key, value) {
    const url = new URL(window.location)
    url.searchParams.set(key, value)
    window.history.pushState({}, '', url)
}

export function deleteQueryParam(key) {
    const url = new URL(window.location)
    url.searchParams.delete(key)
    window.history.pushState({}, '', url)
}
